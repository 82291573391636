@use "sass:math";
@use '~@THC/styles/core' as core;

@use '~@THC/styles/_typography.scss' as typography;

.wrapper {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: core.$space * 20;
	padding-top: core.$space * 12;
	text-align: center;

	.M404 {
		margin-bottom: core.$space * 9;
		max-width: core.$space * 50;
		width: 100%;
	}

	.heading {
		@include typography.heading2();

		margin-bottom: core.$space * 5;
	}

	.text {
		@include typography.large;

		margin-bottom: core.$space * 7;
		padding-bottom: 0;
	}

	.button {
		max-width: core.$space * 50;
		width: 100%;
	}
}
